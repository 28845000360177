// Define defaults for each variable.
// TODO: factor out a local overrides file for these
$base-font-family: Palatino, "Times New Roman", Times;
$monospace-font-family: Monaco, Menlo, Consolas, "Courier New", DotumChe, monospace !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2568ba !default;

$grey-color:       #757575 !default;
$grey-color-light: adjust-color($grey-color, $lightness: 45%) !default;
$grey-color-dark:  adjust-color($grey-color, $lightness: -20%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;



// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit * 0.5;
//     padding-left: $spacing-unit * 0.5;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials.
@import
  "whiteglass/custom-variables",
  "whiteglass/base",
  "whiteglass/layout",
  "whiteglass/syntax-highlighting",
  "whiteglass/custom-styles.scss"
;
