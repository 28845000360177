.site-header {
  min-height: $header-spacing;
  .site-title {
    font-size: $base-font-size * 2;
  }
}

.site-title {
  font-size: $base-font-size * 1.5;
  line-height: $header-spacing;
  letter-spacing: unset;
  font-variant: small-caps;
  & {
    color: $text-color;
  }
}

.subtitle {
  font-variant: small-caps;
  border-top: 1px solid;
  border-bottom: 2px solid;
  font-size: large;
  clear: both;
}

.site-nav {
  line-height: $header-spacing;
}

.site-footer {
  font-size: $base-font-size * 0.8;
}

.page-heading {
  font-size: $base-font-size * 1.25;
}

.post-list {
  .post-title {
    font-size: $base-font-size * 1.5;
  }
}

.post-archives {
  .post-link {
    font-size: $base-font-size * 1.25;
  }
}
