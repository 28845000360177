@charset "utf-8";

// Our variables
$base-font-family: Bitter, "Apple SD Gothic Neo", AppleGothic, NanumBarunGothic, "Malgun Gothic", Dotum, sans-serif;
$monospace-font-family: Monaco, Menlo, Consolas, "Courier New", DotumChe, monospace;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2568ba;

$grey-color:       #757575;
$grey-color-light: adjust-color($grey-color, $lightness: 45%);
$grey-color-dark:  adjust-color($grey-color, $lightness: -20%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// whiteglass also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit * 0.5;
//         padding-left: $spacing-unit * 0.5;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials from the `whiteglass` theme.
@import "whiteglass";
